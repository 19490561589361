import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { ArrowRight as ArrowRightIcon } from "@styled-icons/material/ArrowRight"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import {
  Section,
  Link,
  NavLink,
  Image as GatsbyImage,
  Pagehead,
  ArticleSubheading,
  Paragraph,
  Breadcrumbs,
  CallToAction,
} from "../components/shared"
import ArticlePreview from "../components/article-preview"
import { RichText } from "../api/contentful"
import { preventSingleWordLastLine } from "../util/typography"
import { readingTime, maxReadingTime, isPost } from "../util/article"
import { ReactComponent as FacebookLogoSvg } from "../images/facebook.svg"
import { ReactComponent as TwitterLogoSvg } from "../images/twitter.svg"

const coverImageMaxWidth = `40em`
const coverImageMaxHeight = `30em`
const CoverImage = styled(GatsbyImage)`
  width: 100%;
  max-width: min(
    ${coverImageMaxWidth},
    calc(${coverImageMaxHeight} * ${props => props.fluid.aspectRatio})
  );
  z-index: 10;

  @media only screen and (max-width: 666px) {
    max-width: 100%;
    min-width: 100%;
  }

  @media only screen and (max-width: 960px) {
    margin-top: 80px;
  }
`

const Title = styled.h2`
  z-index: 10;
  font-size: 2rem;
  line-height: 1.1;
  margin: 0;
  margin-top: 0.3em;
  padding-left: 21%;

  @media only screen and (max-width: 800px) {
    padding-left: 12.5%;
  }
`

const Aside = styled.aside`
  float: right;
  margin: 0 1em;
  width: 12em;

  @media only screen and (max-width: 666px) {
    float: none;
    width: 100%;
    margin: 0 0 2em 0;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media only screen and (max-width: 480px) {
    margin: 0 0 2em 0;
    flex-direction: column;
    align-items: stretch;
  }
`

const FrontmatterContainer = styled.dl`
  display: grid;
  grid-template-columns: max-content auto;
  margin: 0;
`

const FrontmatterKey = styled.dt`
  margin: 0;
  padding: 0.5em 0.8em 0.3em 0;
  font-weight: bold;
  grid-column: 1;
  &:not(:first-of-type) {
    border-top: 2px solid;
  }
  @media only screen and (max-width: 480px) {
    padding: 0.5em 2em 0.3em 0;
  }
`

const FrontmatterValue = styled.dd`
  margin: 0;
  padding: 0.5em 0.8em 0.3em 0;
  grid-column: 2;

  &:not(:first-of-type) {
    border-top: 2px solid;
  }
`

const ImageSources = styled.ul`
  padding: 0;
  list-style: none;
`

const ImageSource = styled.li``

const NamePart = styled.span`
  white-space: nowrap;
`

const ReadingTime = styled.div`
  position: absolute;
  top: -100vh;
  left: calc((100vw - 100%) / -2);
  bottom: -3em;
  width: ${props =>
    props.$showReadingTime
      ? `calc((100vw - 100%) / 2 + 100% * ${props.$readingRatio})`
      : `0`};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
  background-color: ${props => props.theme.readingTimeBackgroundColor};
  @media only screen and (max-width: 666px) {
    bottom: 1em;
  }

  /* TODO: IE11 does not support transitioning values set with calc() */
  transition-property: width;
  transition-duration: ${props => props.theme.readingTimeGrowDuration};
  transition-delay: ${props => props.theme.readingTimeGrowDelay};


  &::after {
    content: '${props => props.$readingTime} min';
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0.1em 0.8em;
    opacity: ${props => (props.$showReadingTime ? 1 : 0)};
    color: ${props => props.theme.readingTimeColor};

    transition-property: opacity;
    transition-duration: ${props => props.theme.readingTimeFadeInDuration};
    transition-delay: ${props => props.theme.readingTimeFadeInDelay};
  }

  @media only screen and (max-width: 960px) {
    bottom: -1.6em;
  }

`

const narrowCss = css`
  max-width: 55%;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 800px) {
    max-width: 75%;
  }

  @media only screen and (max-width: 666px) {
    margin-left: 0;
    margin-right: 0;
    max-width: 80%;
  }

  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
`

const ArticleContainer = styled.article`
  font-size: 1.25rem;

  & > :first-child {
    font-weight: bold;
  }

  & > h3,
  & > h4,
  & > h5,
  & > h6,
  & > p,
  & > ol,
  & > ul {
    ${narrowCss}
  }
`

const Image = styled.img`
  float: right;
  clear: right;
  margin: 1.2em 0 1em 1em;
  max-width: 20em;
  @media only screen and (max-width: 800px) {
    max-width: 15em;
  }
  @media only screen and (max-width: 480px) {
    float: none;
    clear: none;
    max-width: 80%;
    margin: 1em 0 1em 0;
  }
`

const ArticleFooter = styled.div`
  ${narrowCss}
  margin-top: 2em;
  display: grid;
  grid-template-columns: minmax(0, max-content) auto;
  align-items: start;
  gap: 1em;
  @media only screen and (max-width: 666px) {
    margin: 2em 0 0 0;
  }
  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 2em;
  }
`

const AuthorArticlesContainer = styled.div``

const AuthorArticlesHeading = styled.h3`
  margin: 0;
  padding: 0.5em 0.8em 0.3em 0;
  font-weight: bold;
  font-size: 1rem;
  border-bottom: 2px solid;
`

const AuthorArticles = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const AuthorArticle = styled.li`
  margin: 1em 0;
`

const AdjacentArticles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: start;
  gap: 2em;
  max-width: 55%;
  margin: 0 auto;

  @media only screen and (max-width: 800px) {
    max-width: 75%;
  }

  @media only screen and (max-width: 666px) {
    max-width: 80%;
    grid-template-columns: 1fr;
    gap: 2em;
    margin: 0;
  }

  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
`

const AdjacentArticle = styled.div`
  max-width: 20em;
`

const AdjacentArticleLabel = styled.h3`
  margin-bottom: 0.4em;
  font-size: 1rem;
  font-weight: bold;
`

const Frontmatter = ({ author, imageSources, publishDate }) => (
  <FrontmatterContainer>
    <FrontmatterKey>Text</FrontmatterKey>
    <FrontmatterValue>
      <NamePart>{author.firstName}</NamePart>{" "}
      <NamePart>{author.lastName}</NamePart>
    </FrontmatterValue>

    {/* TODO: "Bild" if there is only one */}
    {imageSources && (
      <>
        <FrontmatterKey>Bilder</FrontmatterKey>
        <FrontmatterValue>
          <ImageSources>
            {imageSources.map((imageSource, index) => (
              <ImageSource key={index}>{imageSource}</ImageSource>
            ))}
          </ImageSources>
        </FrontmatterValue>
      </>
    )}

    <FrontmatterKey>Datum</FrontmatterKey>
    <FrontmatterValue>
      {Intl.DateTimeFormat("de-DE", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(new Date(publishDate))}
    </FrontmatterValue>
  </FrontmatterContainer>
)

const logoCss = css`
  height: 1.8em;

  [data-color="foreground"] {
    fill: currentColor;
  }

  [data-color="background"] {
    fill: ${props => props.theme.backgroundColor || "#ffffff"};
  }
`

const FacebookLogo = styled(FacebookLogoSvg)`
  ${logoCss}
`

const TwitterLogo = styled(TwitterLogoSvg)`
  ${logoCss}
`

const Teaser = styled.span``

const TeaserIcon = styled(ArrowRightIcon)`
  position: relative;
  bottom: 0.05em;
  height: 1.1em;
  transition: transform 0.2s;
`

const ShareLinksContainer = styled.div`
  overflow: hidden;
  transition: max-height 0.2s;
`

const ShareLinks = styled.ul`
  margin: 0;
  padding: 1em 0 0.25em 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
  color: ${props => props.theme.color};
`

const ShareLink = styled.li``

const SocialMediaContainer = styled.div`
  grid-column: 1/-1;
  margin: 2em 0 0 0;
  min-height: 6em;

  @media only screen and (max-width: 666px) {
    grid-column: 4;
    grid-row: 1 / 3;
    margin: 0;
    width: auto;
    min-height: unset;
  }
  @media only screen and (max-width: 480px) {
    grid-column: 1 / -1;
    grid-row: 4;
    margin: 2em 0 0 0;
  }
`

const SMCallToAction = styled(CallToAction)`
  padding: 0.25em 0.5em;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;

  ${ShareLinksContainer} {
    max-height: 0;
  }
  ${props => (props.$isOpen ? `&` : `&.never`)} {
    ${TeaserIcon} {
      transform: rotate(90deg);
    }
    ${ShareLinksContainer} {
      max-height: 6em;
    }
  }

  &::after {
    content: "";
    display: none;
  }
`

const ShareUrl = ({ slug }) => {
  const data = useStaticQuery(graphql`
    query SiteUrlQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const { siteUrl } = data.site.siteMetadata

  const url = `${siteUrl}/artikel/${slug}`

  // TODO: open new small window?
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}`
  // const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=&summary=&source=`
  // const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <SocialMediaContainer>
      <SMCallToAction
        as="button"
        type="button"
        onClick={() => {
          setIsOpen(open => !open)
          document.activeElement.blur()
        }}
        $isOpen={isOpen}
      >
        <Teaser $isOpen={isOpen}>
          In Social Media teilen
          <TeaserIcon />
        </Teaser>
        <ShareLinksContainer>
          <ShareLinks>
            <ShareLink>
              <NavLink
                to={facebookShareUrl}
                target="_blank"
                onClick={event => {
                  event.stopPropagation()
                }}
                title="Facebook"
              >
                <FacebookLogo />
              </NavLink>
            </ShareLink>
            <ShareLink>
              <NavLink
                to={twitterShareUrl}
                target="_blank"
                onClick={event => {
                  event.stopPropagation()
                }}
                title="Twitter"
              >
                <TwitterLogo />
              </NavLink>
            </ShareLink>
            {/* <ShareLink>
              <NavLink to={linkedinShareUrl} target="_blank" title="LinkedIn">
                LinkedIn
              </NavLink>
            </ShareLink> */}
          </ShareLinks>
        </ShareLinksContainer>
      </SMCallToAction>
    </SocialMediaContainer>
  )
}

const Article = ({
  pageContext: {
    article: { title, slug, author, coverImage, text, id },
    article,
    previousArticle,
    nextArticle,
  },
}) => {
  const [showReadingTime, setShowReadingTime] = React.useState(false)
  React.useEffect(() => {
    setShowReadingTime(true)
  }, [])

  const articleReadingTime = readingTime(text)

  const shownReadingTime = showReadingTime ? articleReadingTime : 0

  const readingRatio = shownReadingTime / maxReadingTime

  return (
    <Layout>
      <SEO title={title} />
      <Pagehead bottomMargin>
        <ReadingTime
          $readingTime={articleReadingTime}
          $readingRatio={readingRatio}
          $showReadingTime={showReadingTime}
        />
        {/* TODO: alt */}
        <CoverImage fluid={coverImage.fluid} alt="" />
        <Title>{preventSingleWordLastLine(title)}</Title>
        <Breadcrumbs
          nodes={[{ title: "Magazin", path: "/magazin" }, { title: "Artikel" }]}
        />
      </Pagehead>
      <Section>
        <Aside>
          <Frontmatter {...article} />
          <ShareUrl slug={slug} />
        </Aside>

        <ArticleContainer>
          <RichText
            node={text}
            h3Component={ArticleSubheading}
            paragraphComponent={Paragraph}
            imageComponent={Image}
          />
        </ArticleContainer>
        <ArticleFooter>
          {isPost(text) ? <div /> : <Frontmatter {...article} />}
          {author.article.length > 1 && (
            <AuthorArticlesContainer>
              <AuthorArticlesHeading>
                Artikel von {author.firstName} {author.lastName}
              </AuthorArticlesHeading>
              <AuthorArticles>
                {author.article
                  .filter(authorArticle => authorArticle.id !== article.id)
                  .reverse()
                  .map(authorArticle => (
                    <AuthorArticle key={authorArticle.slug}>
                      <Link to={`/artikel/${authorArticle.slug}`}>
                        {authorArticle.title}
                      </Link>
                    </AuthorArticle>
                  ))}
              </AuthorArticles>
            </AuthorArticlesContainer>
          )}
        </ArticleFooter>
      </Section>
      <Section>
        <AdjacentArticles>
          {nextArticle && (
            <AdjacentArticle>
              <AdjacentArticleLabel>nächster Artikel</AdjacentArticleLabel>
              <ArticlePreview {...nextArticle} coverImage={null} />
            </AdjacentArticle>
          )}
          {previousArticle && (
            <AdjacentArticle>
              <AdjacentArticleLabel>vorheriger Artikel</AdjacentArticleLabel>
              <ArticlePreview {...previousArticle} coverImage={null} />
            </AdjacentArticle>
          )}
        </AdjacentArticles>
      </Section>
      <Contact />
    </Layout>
  )
}

export default Article
